<template>
  <div class="web_box">
    <van-nav-bar
      :title="isLogin == true ? '绑定' : '注册'"
      left-arrow
      @click-left="toBack()"
      fixed
      class="header_class"
      :border="false"
    >
      <div class="back_icon" slot="left" />
    </van-nav-bar>
    <div id="bg">
      <!-- form1 -->
      <div class="forml">
        <!-- 第一步的form -->
        <div class="form_box">
          <div class="input_box">
            <p>手机号</p>
            <div class="input_text">
              <span class="icon icon1"></span>
              <input
                type="text"
                autocomplete="off"
                placeholder="请输入手机号码"
                v-model="form.mobile"
              />
            </div>
          </div>
          <div class="input_box">
            <p>姓名</p>
            <div class="input_text">
              <span class="icon icon6"></span>
              <input
                type="text"
                autocomplete="off"
                placeholder="请输入姓名"
                v-model="form.username"
              />
            </div>
          </div>
          <div class="input_code">
            <div class="input_details code_box">
              <p>验证码</p>
              <div class="input_text">
                <span class="icon icon2"></span>
                <input
                  type="text"
                  autocomplete="off"
                  placeholder="请输入验证码"
                  name="code"
                  v-model="form.msgCode"
                />
              </div>
            </div>
            <div class="code_btn">
              <span @click="getCode" v-if="isCode">获取验证码</span>
              <span v-else>{{ codeTime }}s后重新获取</span>
            </div>
          </div>
          <div class="input_box" v-if="isLogin == false">
            <p>密码</p>
            <div class="input_text">
              <span class="icon icon3"></span>
              <input
                type="password"
                autocomplete="new-password"
                placeholder="请输入密码"
                name="password"
                v-model="form.password"
              />
            </div>
          </div>
          <div class="input_box" v-if="isLogin == false">
            <p>再次输入</p>
            <div class="input_text">
              <span class="icon icon3"></span>
              <input
                type="password"
                autocomplete="new-password"
                placeholder="请再次输入密码"
                name="re_password"
                v-model="form.re_password"
              />
            </div>
          </div>
          <div class="input_box">
            <p>邀请码</p>
            <div class="input_text">
              <span class="icon icon4"></span>
              <input
                type="text"
                autocomplete="off"
                placeholder="邀请码(可不填)"
                readonly
                v-model="inviteCode"
              />
            </div>
          </div>
        </div>
        <p class="tip">密码长度须为6-20非纯数字，可包含字母、数字或下划线</p>
        <div class="contact" @click="toRead()">
          <span
            class="change_class"
            :class="isRead == true ? 'change_class_active' : ''"
          ></span>
          <span>勾选同意 <a>《用户服务协议》</a></span>
        </div>
        <van-dialog
          v-model="showContact"
          confirmButtonText="确定"
          confirmButtonColor="#00CCFF"
          @confirm="showContact = false"
          style="    height: 70%;"
        >
          <contact></contact>
        </van-dialog>
        <div class="next_btn" @click="toRegister()">
          <button v-if="isLogin == false">注册</button>
          <button v-else>绑定</button>
        </div>
      </div>
      <!-- <van-button
        v-if="isLogin == false"
        type="primary"
        @click="handLogin"
        class="weiXin"
      ></van-button> -->
    </div>
  </div>
</template>
<script>
// import wx from "weixin-js-sdk";
import {mapMutations} from "vuex";
// import axios from "axios";
// import {wxLogin} from "@/api/apis";
import contact from "../components/contact";
// register
import {
  uploadRegisterPicHead,
  registerSendCode,
  register,
  loginByWx,
  bindAccount,
} from "../utils/api";
import {Toast} from "vant";
export default {
  components: {
    contact,
  },
  data() {
    return {
      step: 1,
      showForm1: true,
      //   表单一
      form: {
        mobile: "",
        msgCode: "",
        password: "",
        re_password: "",
        username: "",
      },
      inviteCode: "",
      isRead: false,
      isCode: true,
      codeTime: 90,
      showContact: false,
      code: "", // 前端获取 code 传给后端调用相应接口
      isLogin: false,
      // loginToken: "",
      userToken: "",
    };
  },
  // watch: {
  //   isLogin(newval, oldval) {
  //     console.log(newval);
  //     console.log(oldval);
  //   },
  // },
  created() {},
  mounted() {
    this.inviteCode = this.$route.query.inviteCode;
    // console.log(this.isLogin, "isLogin");
    // 从 window.location.href 中截取 code 并且赋值
    if (window.location.href.indexOf("CSDN_state") !== -1) {
      // 此方法仅供参考！！！
      this.code = window.location.href
        .split("?")[1]
        .split("=")[1]
        .split("&")[0];
    }

    if (this.code) {
      // 存在 code 直接调用接口
      this.isLogin = true;
      this.handGetUserInfo(this.code);
    }
  },
  methods: {
    ...mapMutations(["changeLogin"]),
    toRead() {
      this.isRead = !this.isRead;
      if (this.isRead) {
        this.showContact = true;
      }
    },

    isEmty(obj) {
      if (obj.mobile == "") {
        Toast("手机号码不能为空");
      } else if (obj.username == "") {
        Toast("姓名不能为空");
      } else if (obj.password == "") {
        Toast("密码不为空");
      } else if (obj.re_password != obj.password) {
        Toast("两次密码不一致");
      } else if (obj.code == "") {
        Toast("请输入验证码");
      } else if (this.isRead != true) {
        Toast("请勾选并阅读《用户服务协议》");
      } else {
        return true;
      }
    },
    isEmty2(obj) {
      if (obj.mobile == "") {
        Toast("手机号码不能为空");
      } else if (obj.username == "") {
        Toast("姓名不能为空");
      } else if (obj.code == "") {
        Toast("请输入验证码");
      } else if (this.isRead != true) {
        Toast("请勾选并阅读《用户服务协议》");
      } else {
        return true;
      }
    },
    // 获取验证码
    getCode() {
      let that = this;
      registerSendCode({
        mobile: this.form.mobile,
      })
        .then(() => {
          that.isCode = false;
          let Timer = setInterval(() => {
            if (that.codeTime > 0) {
              that.codeTime--;
            } else {
              that.codeTime = 90;
              that.isCode = true;
              clearInterval(Timer);
            }
          }, 1000);
        })
        .catch();
    },

    handLogin() {
      // 重定向地址重定到当前页面，在路径获取 code
      const hrefUrl = window.location.href;
      // https://app.aijhealth.com
      // window.location.href;
      if (this.code === "") {
        window.location.href = `
						https://open.weixin.qq.com/connect/oauth2/authorize
						?appid=wx893990438a4b1cd7
						&redirect_uri=${encodeURIComponent(hrefUrl)}
						&response_type=code
						&scope=snsapi_userinfo
						&state=CSDN_state#wechat_redirect
					`;
      }
      // if (this.code === "") {
      //   window.location.href = `
      // 			https://open.weixin.qq.com/connect/oauth2/authorize
      // 			?appid=wx893990438a4b1cd7
      // 			&redirect_uri=${encodeURIComponent(hrefUrl)}
      // 			&response_type=code
      // 			&scope=snsapi_userinfo
      // 			&state=CSDN_state#wechat_redirect
      // 		`;
      // }
    },
    handGetUserInfo(code) {
      let _that = this;
      // axios
      //   .get("http://192.168.1.126:9080/dayi/api/user/loginByWx", {
      //     params: {
      //       code: code,
      //       channel: 1,
      //       inviteCode: _that.inviteCode,
      //     },
      //   })
      //   .then(function(response) {
      //     console.log(response);
      //   })
      //   .catch(function(error) {
      //     alert(error);
      //   });
      // 调用后端接口，参数为 code 剩下工作量交给后端即可
      // console.log("11111");
      loginByWx({code: code, channel: 1, inviteCode: _that.inviteCode})
        .then((success) => {
          // Toast.success(res.code);
          console.log(success);
          // that.loginToken = res.data.token;
          // window.localStorage.setItem("wxToken", success.data.token);
          // location.href = location.href + "&token =" + res.data.token;
          if (success.code == 200) {
            // 将用户token保存到vuex中
            setTimeout(() => {
              _that.$router.push("/download");
            }, 200);
            // window.location.href = "https://app.aijhealth.com/#/download";
          } else if (success.code == 5) {
            _that.userToken = success.data.token;
            _that.changeLogin({Authorization: _that.userToken});
            console.log(success);
            _that.isLogin = true;
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    toRegister() {
      let _that = this;
      if (_that.isLogin == false) {
        if (_that.isEmty(_that.form)) {
          _that.form.inviteCode = this.inviteCode;
          register(_that.form)
            .then((res) => {
              Toast.success(res.msg);
              setTimeout(() => {
                _that.$router.push("/download");
              }, 200);
            })
            .catch({});
        }
      } else {
        // console.log(window.localStorage.getItem("wxToken"));
        // this.form.token = this.$route.query.token;
        if (_that.isEmty2(_that.form)) {
          _that.form.inviteCode = this.inviteCode;
          // this.form.token = window.localStorage.getItem("wxToken");
          bindAccount(_that.form)
            .then((res) => {
              Toast.success(res.msg);
              setTimeout(() => {
                _that.$router.push("/download");
              }, 200);
            })
            .catch({});
        }
      }

      // if (i == 1) {
      //   if (this.isEmty(this.form)) {
      //     this.showForm1 = false;
      //     this.showForm2 = true;
      //     this.showForm3 = false;
      //     this.step = 2;
      //   }
      // } else if (i == 0) {
      //   this.showForm1 = true;
      //   this.showForm2 = false;
      //   this.showForm3 = false;
      //   this.step = 1;
      // } else if (i == 2) {
      //   this.showForm1 = false;
      //   this.showForm2 = false;
      //   this.showForm3 = true;
      //   this.step = 3;
      // } else if (i == 3) {
      //   let newObj = Object.assign(this.form, this.form3);
      //   newObj.birthday = this.dateText;
      //   newObj.userName = this.userName;
      //   newObj.inviteCode = this.inviteCode;
      //   newObj.sex = this.sexItem;
      //   newObj.bloodType = this.bleedItem;
      //   newObj.header = this.picHead;
      //   register(newObj)
      //     .then((res) => {
      //       Toast.success(res.msg);
      //       setTimeout(() => {
      //         window.android.startLoginActivity();
      //       }, 200);
      //     })
      //     .catch({});
      // }
    },
    // http://182.254.227.204/dayi/client/rules.html?type=2
    // 上传
    afterRead(file) {
      this.form2.fileList = [];
      uploadRegisterPicHead({imgData: file.content})
        .then((res) => {
          this.form2.fileList.push({url: res.picHead});
        })
        .catch();
    },
    // 点击图片回调拉起上传
    upLoad() {
      this.$refs.upload.chooseFile();
    },
    handleEndDateConfirm(value) {
      var date = value;
      var m = date.getMonth() + 1;
      var d = date.getDate();
      if (m >= 1 && m <= 9) {
        m = "0" + m;
      }
      if (d >= 0 && d <= 9) {
        d = "0" + d;
      }
      var timer = date.getFullYear() + "-" + m + "-" + d;
      this.dateText = timer;
      this.dateShow = false;
      this.datePicker = "";
      console.log(value);
    },
    getQueryVariable(variable) {
      var query = window.location.search.substring(1);
      var vars = query.split("&");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) {
          return pair[1];
        }
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
#bg {
  height: calc(100vh - 88px);
  background-image: url("/assets/register/bg.png");
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-top: 88px;
  overflow-y: scroll;
}
// .forml,
.forml > .form_box {
  width: 601px;
  background-image: url("../assets/register/form_box.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin: 22px auto;
  padding: 28px 0 62px;
  .input_box {
    width: 521px;
    height: 150px;
    margin: 0 auto;
    margin-top: 28px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url("../assets/register/input_bg1.png");
    p {
      color: #00ccff;
      font-size: 26px;
      padding: 13px 20px;
    }
  }
  .input_code {
    .code_box .input_text input {
      width: 150px;
    }
    width: 521px;
    height: 150px;
    margin: 0 auto;
    margin-top: 28px;
    display: flex;
    justify-content: left;
    align-items: flex-end;
    .input_details {
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-image: url("../assets/register/input_bg2.png");
      width: 304px;
      height: 100%;
      p {
        color: #00ccff;
        font-size: 26px;
        padding: 13px 20px;
      }
    }

    .code_btn {
      width: 197px;
      height: 88px;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-image: url("../assets/register/btn_bg.png");
      margin-left: 19px;
      span {
        line-height: 88px;
        display: inline-block;
        width: 100%;
        text-align: center;
        color: #fff;
        font-size: 26px;
      }
    }
  }
  .input_box:first-of-type {
    margin-top: 0;
  }
  .input_text {
    display: flex;
    justify-content: left;
    align-items: center;
    .icon {
      width: 30px;
      height: 30px;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      display: inline-block;
      margin: 30px;
    }
    .icon1 {
      background-image: url("../assets/register/icon1.png");
    }
    .icon2 {
      background-image: url("../assets/register/icon2.png");
    }
    .icon3 {
      background-image: url("../assets/register/icon3.png");
    }
    .icon4 {
      background-image: url("../assets/register/icon4.png");
    }
    .icon6 {
      background-image: url("../assets/register/info_icon.png");
    }

    input {
      flex: 1;
      margin-right: 30px;
      height: 90px;
      background-color: transparent;
      outline: none;
      border: none;
      color: #00ccff;
      -webkit-autofill {
        -webkit-box-shadow: 0 0 0px 1000px white inset;
      }
    }
  }
}
.tip,
.contact {
  color: #37708f;
  font-size: 24px;
  width: 601px;
  text-align: left;
  margin: 0 auto;
}
.contact {
  margin-top: 29px;
  .change_class {
    display: inline-block;
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url("../assets/register/change.png");
    vertical-align: bottom;
    margin-right: 8px;
  }
  .change_class_active {
    background-image: url("../assets/register/change_active.png");
  }
  span {
    a {
      color: #00ccff;
    }
  }
}
.next_btn {
  width: 604px;
  height: 88px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin: 39px auto 71px;
  background-image: url("../assets/register/sub_bg.png");
  button {
    width: 100%;
    height: 100%;
    line-height: 88px;
    color: #00ccff;
    font-size: 30px;
    background-color: transparent;
    text-align: center;
    outline: none;
    border: none;
  }
}
// form2
.form2 {
  .header {
    display: flex;
    justify-content: left;
    align-items: center;
    .name {
      display: flex;
      justify-content: left;
      align-items: flex-end;
      h3 {
        font-size: 32px;
        font-weight: bold;
        color: #00ccff;
      }
      span {
        display: inline-block;
        width: 32px;
        height: 32px;
        background-image: url("../assets/register/edit_icon.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin-left: 20px;
      }
    }
  }
  .blue_line {
    width: 90%;
    height: 1px;
    margin: 0 auto;
    background-color: #00ccff;
    opacity: 0.6;
  }
  .emty {
    width: 100%;
    height: 50px;
  }
  .item_box {
    width: 90%;
    margin: 39px auto 0;
    p {
      color: #00ccff;
      font-size: 32px;
      position: relative;
      .birth_icon {
        position: absolute;
        right: 0;
        top: 10px;
        display: inline-block;
        background-image: url("../assets/register/left_icon.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        width: 30px;
        height: 30px;
      }
      .birth_text {
        display: inline-block;
        text-align: right;
        width: 75%;
        font-size: 26px;
      }
    }

    ul {
      width: 100%;
      display: flex;
      justify-content: left;
      align-items: center;
      margin-top: 20px;
      li {
        width: 114px;
        margin-left: 10px;
        padding: 11px 0;
        text-align: center;
        background-color: rgba($color: #00ccff, $alpha: 0.2);
        border-radius: 30px;
        color: #6cc7e5;
        font-size: 26px;
      }
      .is_check {
        border: 1px solid #00ccff;
      }
    }
  }
  .birth_box {
    margin-bottom: 10px;
  }
  .edit_bpx {
    padding: 90px;
    height: 100px;

    .username_box {
      height: 60px;
      border-bottom: 1px solid gray;
      border-top: 1px solid gray;
      border-radius: 10px;
      input {
        border: none;
        padding: 0 10px;
        height: 100%;
        font-size: 26px;
        outline: 0;
      }
    }
    button {
      width: 100px;
      height: 60px;
      line-height: 60px;
      background-color: #00ccff;
      border-radius: 20px;
      margin-top: 20px;
      margin-left: 200px;
      color: #fff;
      font-size: 26px;
    }
  }
}
// form3
.form3 {
  .people_box {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 0;
    .people_img {
      width: 270px;
      height: 370px;
    }
    .info_box {
      width: 324px;
      p {
        color: #00ccff;
        font-size: 32px;
      }

      .input_container {
        width: 100%;
        height: 88px;
        background-image: url("../assets/register/input_box.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin-top: 30px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        span {
          color: #00ccff;
          font-size: 26px;
          margin-right: 5px;
        }
        input {
          color: #00ccff;
          font-size: 26px;
          height: 100%;
          background-color: transparent;
          outline: none;
          border: none;
          width: 150px;
        }
      }
    }
    .health {
      width: 616px;
      .input_container input {
        width: auto;
      }
    }
  }
}
.top_box {
  width: 690px;
  margin: 30px auto 0;
  display: flex;
  justify-content: left;
  align-items: center;
  background-image: url("../assets/register/line.png");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
  height: 88px;
  img {
    width: 62px;
    height: 62px;
  }
  p {
    margin-left: 38px;
    color: #00ccff;
    font-size: 32px;
  }
}
.inner_box {
  width: 690px;
  background-color: rgba($color: #002944, $alpha: 0.8);
  margin: 20px auto;
}
</style>
<style scoped>
.inner_box >>> .van-uploader__upload {
  margin: 40px;
  width: 140px;
  height: 140px;
  background-image: url("../assets/register/header.png");
  background-color: transparent;
  background-size: 100%;
  background-repeat: no-repeat;
  border-radius: 50%;
}
.inner_box >>> .van-uploader__preview-image {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  /* margin: 40px; */
}
.inner_box >>> .van-uploader__preview {
  margin: 40px;
  position: absolute;
  z-index: 1;
}
.inner_box >>> .van-uploader__upload i {
  display: none;
}
.web_box >>> .van-dialog__content {
  height: 90%;
}
.weiXin {
  width: 100px;
  height: 100px;
  background-image: url("../assets/register/weixin_icon.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-color: transparent;
  margin: 30px auto;
  border: none;
  display: table;
}
</style>
